<template>
    <v-card class="pa-3 mb-6">
        <v-card-title>
            {{ report.object_name }} / {{ report.point_name }}
        </v-card-title>
        <v-card-text>
            <h3 class="font-weight-medium mb-3"> {{ $tc('labels.inspection', 2) }} </h3>
            <v-col>
            <InspectionsSummaryTable
                :inspections="report.inspections"
            ></InspectionsSummaryTable>
                </v-col>
            <v-row>
            <v-col>
            <InformationItem
                :title="$t('sest_luv.required_points')"
                :text="report.required_points"
                :title-size="titleSize"
                name="required_points"
            ></InformationItem>

            <InformationItem
                :title="$t('sest_luv.achived_points')"
                :text="report.achived_points"
                :title-size="titleSize"
                name="required_points"></InformationItem>
                                </v-col>
            <v-col>
            <InformationItem
                :title="$t('sest_luv.completion_rate')"
                :title-size="titleSize"
                :text="Math.round(report.completion_rate * 100) + '%'"
                name="required_points"></InformationItem>
            <InformationItem
                :title="$t('sest_luv.passed')"
                :title-size="titleSize"
                :text="report.passed.toString()"
                name="required_points"></InformationItem>
            </v-col>
                </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import InformationItem from '@/components/customers/InformationItem';
import InspectionsSummaryTable from './InspectionsSummaryTable';

export default {
    name: 'InspectionsCard',
    components: {
        InformationItem,
        InspectionsSummaryTable
    },
    props: {
        report: {
            type: Object,
            required: true
        }
    },
    computed: {
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        titleSize () {
            return {
                lg8: true,
                md8: true,
                sm5: true,
                xs5: true
            };
        }
    }
};
</script>

<style scoped>

</style>
