<template>
    <div class="pa-4 mb-7">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('sest_luv.title_with_comments') }}</h1>
        <v-col>
            <v-row>
                <FiltersBar :withSearch="false"
                            ref="filtersBar"></FiltersBar>
            </v-row>
            <v-row justify="end"
                   class="mr-5">
                <TextField
                    :label="$t('labels.completion_rate')"
                    name="complition_rate"
                    :hint="$t('hints.completion_rate')"
                    v-model="completionRate"
                    :rules="{ required: true, decimal: 2 }"
                    class="mr-6"
                    style="width: 200px"
                ></TextField>
                <v-btn
                    class="mb-8" color="primary"
                    @click="fetchSestLuvReportWithComments"
                > {{ $t('buttons.refresh') }}
                </v-btn>
            </v-row>
        </v-col>
        <v-row v-for="(index) in Math.ceil(sestLuvReports.length / 3)"
               :key="index">
            <v-col class="justify-space-around" v-for="report in sestLuvReports.slice(3 * (index - 1), 3 * index)"
                   :key="report.id">
                <InspectionsCard
                    :report="report"
                ></InspectionsCard>
            </v-col>
        </v-row>
        <InspectionsCommentsTable
            :inspectionsComments="sestLuvReportsComments"
        ></InspectionsCommentsTable>
    </div>
</template>

<script>
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debounce from 'lodash.debounce';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import filtering from '@/mixins/filtering';
import { mapState } from 'vuex';
import InspectionsCard from '@/components/inspections/InspectionsCard';
import InspectionsCommentsTable from '@/components/inspections/InspectionsCommentsTable';
import MonthPickerFilter from '@/models/filters/monthPickerFilter';
import TextField from '@/components/widgets/forms/TextField';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'SestLuvReportsComments',
    components: {
        FiltersBar, TextField, InspectionsCard, InspectionsCommentsTable
    },
    mixins: [
        filtering,
        debouncedLocations,
        paginationSettings
    ],
    data: (vm) => ({
        pagination: vm.setupPagination(),
        debouncedFetchEZTReports: null,
        loader: false,
        sortingColumn: null,
        completionRate: 95.5
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapState('microserviceReports', [
            'sestLuvReports',
            'sestLuvReportsComments'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        currentMonth () {
            return new Date().getMonth() + 1;
        },
        filtersConfig () {
            return [
                new MonthPickerFilter({
                    model: FILTERS.CHOSEN_MONTH,
                    label: this.$t('labels.month'),
                    max: `${this.currentMonth}`
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        }
    },
    methods: {
        generateFilters () {
            const params = {};
            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f === FILTERS.CHOSEN_MONTH) {
                        params.year = this.filters[f].split('-')[0];
                        params.month = this.filters[f].split('-')[1];
                    } else {
                        params[f] = this.filters[f];
                    }
                }
            });
            return params;
        },
        fetchSestLuvReportWithComments () {
            this.loader = true;
            const params = {
                completion_rate: this.completionRate,
                ...this.generateFilters()
            };
            this.$store.dispatch('microserviceReports/getSestLuvReportWithComments', {
                params: params
            }).then(() => {
                this.loader = false;
            });
        }
    },
    created () {
        this.debouncedFetchSestLuvReportWithComments = debounce(this.fetchSestLuvReportWithComments, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchSestLuvReportWithComments);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
            vm.$store.dispatch('locations/getLocations')
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.LOCATION_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }

};
</script>

<style scoped>

</style>
