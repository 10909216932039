<template>
    <v-layout column class="mr-3" style="overflow-y: auto; height: 30vh"
              v-if="inspectionsComments.length !== 0">
        <h1 class="font-weight-medium">
            {{ $t('sest_luv.comments') }}</h1>
        <v-data-table :headers="headers"
                      :items="inspectionsComments"
                      disable-pagination
                      dense
                      hide-default-footer
                      class="elevation-3"
                      style="margin: 10px">
            <template v-slot:item="{ item, index }">
                <InfiniteScroll tag="tr"
                                :items-length="inspectionsComments.length"
                                :index="index"
                                :item="item">
                    <template>
                        <td>
                            {{ item.id }}
                        </td>
                        <td>
                            {{ PARSE_DATE(item.date_time) + ' ' + PARSE_TIME(item.date_time) }}
                        </td>
                        <td>
                            {{ item.point_name }}
                        </td>
                        <td>
                            {{ item.question }}
                        </td>
                        <td>
                            {{ item.answer }}
                        </td>
                    </template>
                </InfiniteScroll>
            </template>
        </v-data-table>
        <v-divider></v-divider>
    </v-layout>
</template>

<script>
import InfiniteScroll from '@/components/widgets/InfiniteScroll';
import { PARSE_DATE, PARSE_TIME } from '@/helpers/dates';

export default {
    name: 'InspectionsCommentsTable',
    components: { InfiniteScroll },
    props: {
        inspectionsComments: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        PARSE_DATE,
        PARSE_TIME
    }),
    computed: {
        headers () {
            return [
                {
                    text: this.$t('id'),
                    value: 'inspection_id',
                    sortable: false
                },
                {
                    text: this.$t('labels.date'),
                    value: 'date_time',
                    sortable: false
                },
                {
                    text: this.$t('labels.points'),
                    value: 'question',
                    sortable: false
                },
                {
                    text: this.$tc('labels.question', 1),
                    value: 'question',
                    sortable: false
                },
                {
                    text: this.$t('labels.answer'),
                    value: 'answer',
                    sortable: false
                }

            ];
        }
    }
};
</script>

<style scoped>

</style>
