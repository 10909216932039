<template>
    <v-layout column class="mr-3" style="overflow-y: auto; height: 20vh">
        <v-data-table :headers="headers"
                      :items="inspections"
                      disable-pagination
                      dense
                      hide-default-footer
                      class="elevation-3"
                      style="margin: 10px">
            <template v-slot:item="{ item, index }">
                <InfiniteScroll tag="tr"
                                :items-length="inspections.length"
                                :index="index"
                                :item="item">
                    <template>
                        <td class="font-weight-bold">{{ item.point_id }}</td>
                        <td>
                            {{ item.date_time }}
                        </td>
                        <td>
                            {{ item.answer }}
                        </td>
                        <td>
                            {{ item.question }}
                        </td>
                    </template>
                </InfiniteScroll>
            </template>
        </v-data-table>
        <v-divider></v-divider>
    </v-layout>
</template>

<script>
import InfiniteScroll from '@/components/widgets/InfiniteScroll';

export default {
    name: 'InspectionsSummaryTable',
    components: { InfiniteScroll },
    props: {
        inspections: {
            type: Array,
            required: true
        }
    },
    computed: {
        headers () {
            return [
                {
                    text: this.$t('id'),
                    value: 'inspection_id',
                    sortable: false,
                    width: '10%'
                },
                {
                    text: this.$t('labels.date'),
                    value: 'inspection_date_time',
                    sortable: false
                },
                {
                    text: this.$t('labels.answer'),
                    value: 'answer',
                    sortable: false
                },
                {
                    text: this.$tc('labels.question', 1),
                    value: 'question',
                    sortable: false
                }

            ];
        }
    }
};
</script>

<style scoped>

</style>
